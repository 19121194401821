.page-footer {
    padding: 30px 0;

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }

    .footer-mobile-large-text {
        @include media-breakpoint-down(sm) {
            font-size: 24px;
            margin: 10px 0 !important;
        }
    }
}

.footer-logo {
    max-width: 140px;
    margin-left: auto;

    img {
        max-width: 100%;
    }
}

.footer-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    padding-top: 25px;

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
        flex-wrap: wrap;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $c-white;
    }
}

.footer-nav-item {
    >p {
        margin-bottom: 7px;
        color: $c-white;
        text-transform: uppercase;
    }
}

.footer-nav-item-list {
    list-style: none;
    padding: 0;

    li {
        color: $c-white;
        font-size: 14px;

        a {
            color: $c-white;
        }
    }
}

.social-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $c-white;
    font-size: 14px;
    padding-left: 30px;

    @include media-breakpoint-down(sm) {
        padding-left: 0px;
    }

    .social-block-text {
        margin-right: 10px;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }

    .social-block-links {
        display: flex;
        flex-shrink: 0;
    }

    .social-block-links-item {
        width: 40px;
        height: 40px;
        margin: 0 7px;
        background-color: $c-gray;
        background-position: center center;
        background-size: 30px 30px;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition-duration: .3s;

        &:hover {
            background-color: #686666;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 0px;
        }

        &.social-block-links-item-inst {
            background-image: url(../assets/images/SocialIcons/inst.svg)
        }

        &.social-block-links-item-fb {
            background-image: url(../assets/images/SocialIcons/fb.svg)
        }

        &.social-block-links-item-vk {
            background-image: url(../assets/images/SocialIcons/vk.svg)
        }
    }
}

.instagram-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 30px;

    @include media-breakpoint-down(sm) {
        padding-left: 0px;
    }

    .instagram-container {
        display: flex;
        flex-wrap: wrap;

        .instagram-item {
            width: 20%;
            padding: 0 7px 7px 0;

            img {
                max-width: 100%;
            }
        }
    }
}

.footer-phone {
    margin-top: 35px;

    @include media-breakpoint-down(sm) {
        margin-top: 10px;
    }
}