section.header {
  position: relative;
  padding: 5px 0px 8px;
  background-color: $c-white;
  transition-duration: .3s;

  @include media-breakpoint-down(sm) {
    padding: 0;

    .active-sticky-header & {
      box-shadow: none !important;
    }
  }

  .active-sticky-header & {
    box-shadow: 0 2px 10px rgba(black, 0.5);
  }
}

.header .header-logo {
  max-width: 190px;

}

.header-main-wrapper {
  display: flex;
  flex-direction: column;
  width: auto;
  transition-duration: .3s;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  p,
  h1 {
    transition-duration: .3s;
  }
}

.header-nav {
  z-index: 0;
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.header-nav-list {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: $c-lightgray;
  list-style: none;

  >li {
    padding: 3px 6px;
    transition-duration: .3s;

    &:last-child {
      .header-nav-list-secondary {
        left: auto;
        right: 0;
        box-shadow: -3px 6px 5px rgba($c-lightblack, 0.3);
      }
    }
  }

  a,
  span {
    font-size: 14px;
    color: $c-gray;
    transition-duration: .3s;

    &:hover {
      color: #b3b3b3;
    }
  }


}

.header-nav-list-with-nav {
  position: relative;

  &:hover {
    .header-nav-list-secondary {
      display: flex;
      flex-direction: column;
      z-index: 100;
      background-color: $c-lightgray;
    }
  }

  .header-nav-list-secondary {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    padding: 0;
    background-color: $c-white;
    transition-duration: .3s;
    white-space: nowrap;
    box-shadow: 3px 6px 5px rgba($c-lightblack, 0.3);

    >li {
      padding: 6px 9px;
    }
  }

  .header-nav-list-secondary-menu {
    position: relative;

    &:hover {
      .header-nav-list-secondary-menu-list {
        display: block;
      }
    }
  }

  .header-nav-list-secondary-menu-list {
    display: none;
    position: absolute;
    background-color: red;
    left: 100%;
    top: 0;
    list-style: none;
    background-color: #E2E3EB;
    border-left: 1px solid #cbccd0;
    box-shadow: 3px 6px 5px rgba(33, 33, 33, 0.3);
    padding: 0;

    li {
      padding: 6px 9px;

      a {
        color: #757575;
        font-size: 14px;
      }
    }
  }
}

.header-top-wrapper {
  @include media-breakpoint-down(sm) {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header-top-wrapper-mobile-nav {
    display: none;
    width: 30px;
    height: 30px;

    img {
      max-width: 100%;
    }


    @include media-breakpoint-down(sm) {
      display: flex;
    }

  }
}

.modal-header-nav {

  .modal-dialog {
    max-width: inherit;
  }

  .modal-header {
    position: relative;
    padding: 0;
    border: none;

    .close {
      position: absolute;
      z-index: 10;
      right: 15px;
      top: 10px;
    }
  }

  .modal-dialog {
    margin: 0;
    height: 100%;
  }

  .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 80%;
    height: 100%;
    border: none;
    box-shadow: none;
    border-radius: 0;

    transition-duration: 0.3s;
    transform: translateX(100%)
  }

  &.animated {
    .modal-content {
      transform: translateX(0);
    }
  }

  .modal-body {
    padding: 0;
  }
}

.modal-header-nav-backdrop {
  opacity: 0;
  transition-duration: .3s;

  &.animated {
    opacity: 0.5;
  }
}

.modal-nav-mobil-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;

    border-bottom: 1px solid $c-lightgray;

    a {
      display: flex;
      padding: 12px 30px 12px 20px;
      color: $c-gray;
      font-weight: 600;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 100%;
      background-image: url(../assets/images/right-arrow.svg);
      right: 0px;
      top: 0;
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center center;
    }
  }
}

@include media-breakpoint-down(sm) {
  .sticky-inner-wrapper {
    position: relative !important;
  }
}