section.promotions {
    padding: 30px 0;
    background-image: url(../assets/images/promotions-bg.jpg);
    background-size: cover;

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }
}

.promotions-header {
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }
}

.promotions-item-text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    line-height: 1;
}

.promotions-item {
    position: relative;
    min-height: 200px;
    display: flex;
    margin-bottom: 20px;
    user-select: none;

    @include media-breakpoint-down(sm) {
        margin-bottom: 0px;
        min-height: 160px;
    }

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: calc(50% - 75px);
        left: calc(50% - 75px);
        width: 150px;
        height: 150px;
        background-color: rgba($c-blue, 0.7);
        transform: rotate(45deg);
        transition-duration: .3s;

        @include media-breakpoint-down(sm) {
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            width: 100px;
            height: 100px;
        }
    }

    &:hover {
        &:before {
            transform: rotate(45deg) scale(1.2)
        }
    }

}