.social-share {

  .dropdown {
    button {
      background-color: transparent !important;
      color: $c-gray !important;
      box-shadow: none !important;
      border: none !important;
      padding: 0;

      &:focus {

        color: $c-gray !important;
      }
    }
  }

  .dropdown-menu {
    >div {
      display: inline-block;
      padding: 0 10px;
    }
  }
}

section.instagram-mobile {
  display: none;
  padding: 15px 0;
  background-image: url(../assets/images/history-mainpage-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .instagram-mobile-bg {
    max-width: 80%;
    margin: auto;
    background-color: rgba($c-blue, 0.7);
    padding: 30px 15px;
    color: $c-white;
  }

  .instagram-mobile-text {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}