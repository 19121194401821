section.services {
  padding: 30px 0;

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }

  .general-tabs-container-without-secondary-tab {

    ul {
      padding-left: 20px;
    }
  }
}

.servises-popup-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .servises-popup-item {
    position: relative;
    min-width: calc(50% - 10px);
    margin-bottom: 15px;
    background: #E2E3EB;
    border-radius: 5px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &.full {
      width: 100%;
    }
  }

  .servises-popup-item-title {
    display: flex;
    padding: 5px 10px;
    font-weight: 600;
  }


  .servises-popup-item-content {
    p {
      margin-bottom: 5px;
    }

    ol {
      margin: 0;
      padding: 5px 0;
      padding-left: 25px;
      padding-right: 10px;

      li {
        margin-bottom: 5px;
      }
    }
  }
}

.services-main-tabs-wrapper {
  flex-direction: column;
  border: none;
  min-height: 100%;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: row;
    justify-content: center;
  }



  .services-main-tab-item {

    @include media-breakpoint-down(sm) {
      width: 50%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 55px !important;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .services-main-tab-item-img {
      width: 40px;
      height: 40px;
      align-self: center;
      transition-duration: .3s;

      @include media-breakpoint-down(sm) {
        width: 70%;
        height: 70%;
      }
    }

    .nav-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100px;
      height: 100px;
      padding: 0;
      background-color: $c-lightgray;
      box-shadow: 0 3px 8px rgba($c-black, 0.4);
      border-radius: 50%;
      transition-duration: .3s;
      text-align: center;
      color: $c-gray;
      font-size: 11px;
      border: none;

      &.active {
        box-shadow: 0 4px 10px rgba($c-black, 0.5);
        background-color: $c-blue;
        color: $c-white;
        border: none;

        .services-main-tab-item-img {
          filter: brightness(0) invert(1);
        }

        @include media-breakpoint-down(sm) {
          color: $c-gray;
        }
      }
    }

    .services-main-tab-item-text {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(sm) {
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.services-main-tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .tab-pane {
    height: 100%;

    >.row {
      height: 100%;
    }
  }
}

#services-main-tab-tabpane-renovationOfApartments,
#services-main-tab-tabpane-renovationOfСottage,
#services-main-tab-tabpane-acceptanceOfOffice,
#services-main-tab-tabpane-acceptanceOfApartments {

  padding-right: 400px;
  padding-bottom: 50px;
  padding-left: 20px;

  .services-button-wrapper {
    position: absolute;
    right: 400px;
    bottom: 0;
    display: flex;

    @include media-breakpoint-down(sm) {
      position: relative;
      right: auto;
      bottom: auto;
      margin-top: 15px;

      button {
        flex-grow: 1;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.services-main-tabs-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include media-breakpoint-down(sm) {
    position: relative;
    min-height: 190px;
    width: 100%;
  }
}


.services-secondary-tab-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: $c-lightgray;
  border: none;

  @include media-breakpoint-down(sm) {
    background: transparent;

    &.box-shadow-half::before,
    &.box-shadow-half::after {
      display: none;
    }
  }

  >.nav-item {
    flex-grow: 1;
    text-align: center;
    margin: 0;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 10px;

      a {
        background: $c-lightgray;
        border-radius: 10px !important;

        &.active {
          background-color: $c-blue !important;
          color: $c-white !important;
        }
      }
    }

    .nav-link {
      position: relative;
      padding: 10px 15px;
      border: none;
      border-radius: 0;
      font-weight: bold;
      color: $c-gray;
      transition-duration: .15s;

      &.active {
        background-color: transparent;
        color: $c-blue;

        // &:after {
        //   content: '';
        //   position: absolute;
        //   left: calc(50% - 10px);
        //   top: 100%;
        //   border: 10px solid transparent;
        //   border-top: 10px solid $c-lightgray;
        //}
      }
    }
  }
}

.services-tab-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  li {
    width: 50%;
    padding: 5px 0;
    color: $c-gray;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:before {
      content: "* "
    }
  }
}

.row-servises-custom {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.fix-ie-row-servises-custom {
  min-width: 130px;

  @include media-breakpoint-down(md) {
    min-width: auto;
  }
}

.services-more {
  cursor: pointer;
}

.services-more-image {
  img {
    width: 100%;
    height: 150px;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }
}