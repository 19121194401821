.form-fifth-step {
  display: flex;

  .form-group {
    margin-bottom: 0;
    margin-right: 30px;
  }

  label {
    margin-bottom: 1px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .form-group {
      margin-right: 0px;
    }

    button {
      margin-top: 15px;
      width: 100%;
      text-align: center !important;
    }
  }
}

.stepper-radio-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form-check {
    display: flex;
    align-items: center;
    flex-basis: 33%;

    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    }
  }

  input,
  label {
    padding: 3px;
  }

  .form-check-input {
    margin-top: 0;
  }

}