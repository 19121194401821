section.history {
  padding: 70px;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    padding: 35px 20px;
  }

  .history-text {
    display: flex;
    text-decoration: none;
    padding: 40px;
    background-color: rgba($c-blue, 0.7);

    h2 {
      line-height: 1.05;
    }

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &.history-mainpage {
    background-image: url(../assets/images/history-mainpage-bg.jpg);
  }

  &.history-aboutpage {
    background-image: url(../assets/images/history-aboutpage-bg.jpg);
    padding: 90px 0;
    background-position: center center;

    @include media-breakpoint-down(sm) {
      background-image: url(../assets/images/history-aboutpage-bg-2.jpg);
      background-position: 40% center;
    }

    .container-fluid {
      padding: 0;
    }

    .row {
      margin: 0;
    }

    div[class^='col'] {
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }

    .history-text {
      max-width: 100%;
      padding: 50px;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 70%;
        padding: 20px;

        p {
          font-size: 18px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 40px 0;
      padding-left: 75px;

      .col-12 {
        padding: 0;
      }
    }
  }
}