.small-modal {
  .modal-content {
    border: none;
  }

  .modal-header {
    background-color: $c-blue;
    padding: 20px;

    button {
      opacity: 0.9;
      margin: -20px -20px -20px 0;

      span {
        color: $c-white;
        font-weight: 400;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 10px 15px;
    }
  }

  .modal-body {
    padding: 30px 40px;

    @include media-breakpoint-down(sm) {
      padding: 10px 15px;
    }
  }

  .finish-step {
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-top: 15px;
      align-items: flex-start;
    }
  }

  .finish-step-boss {
    margin: 0 30px;
    max-width: 200px;

    @include media-breakpoint-down(sm) {
      max-width: 100px;
      margin: auto;
      margin-bottom: 10px;
    }
  }

  .finish-step-logo {
    margin: auto;
    max-width: 271px;

    @include media-breakpoint-down(sm) {
      max-width: 100px;
      margin: auto;
      margin-bottom: 10px;
    }
  }
}