h2.h2-large {
  font-size: 52px;

  @include media-breakpoint-down(sm) {
    font-size: 34px;
  }
}

h2 {
  font-size: 42px;

  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }
}

h2.h2-small {
  font-size: 34px;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

h3 {
  font-size: 28px;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

h4 {
  font-size: 23px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

h5 {
  font-size: 16px;
}

.text-size-120 {
  font-size: 120px;

  @include media-breakpoint-down(sm) {
    font-size: 50px;
  }
}

.text-size-80 {
  font-size: 80px;

  @include media-breakpoint-down(sm) {
    font-size: 42px;
  }
}

.text-size-30 {
  font-size: 30px;

  @include media-breakpoint-down(sm) {
    font-size: 26px;
  }
}

.text-size-26 {
  font-size: 26px;

  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
}

.text-size-24 {
  font-size: 24px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

.text-size-20 {
  font-size: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.text-size-18 {
  font-size: 18px;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}


.text-small-14 {
  font-size: 14px;
}

.text-small {
  font-size: 12px;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.text-white {
  color: $c-white;
}

.text-gray {
  color: $c-gray;
}

.text-blue {
  color: $c-blue;
}

a.text-blue {
  transition-duration: .3s;

  &:hover {
    text-decoration: none;
    color: $c-blue-hover;
  }
}

.line-height-1 {
  line-height: 1;
}

.line-height-small {
  line-height: 1.2;
}

.line-height-large {
  line-height: 1.8;
}


.phone-link {
  font-size: 22px;
  color: $c-gray;
  transition-duration: .3s;
  line-height: 1;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }

  &:hover {
    text-decoration: none;
    color: #464646;
  }
}

.text-md-justify {
  @include media-breakpoint-up(md) {
    text-align: justify !important;
  }
}