.banner {
    padding: 30px 0;
    background-color: $c-blue;

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }

    .banner-number-wrapper {
        display: flex;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    .banner-number {
        width: 60px;
        height: 60px;
        text-align: center;
        flex-shrink: 0;
        font-size: 40px;
        border-radius: 50%;
        border: 2px solid $c-white;
        margin-right: 10px;
    }

    .banner-number-item {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            margin: 0;

            &:first-of-type {
                margin-bottom: 20px;
            }
        }


        p {
            font-size: 18px;
            margin: 0;
        }

        &:first-of-type {
            margin-right: 30px;
        }
    }
}