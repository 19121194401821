section.estimate {
  padding: 30px 0;

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }
}

.estimate-large-image {
  min-height: 1000px;

  @include media-breakpoint-down(sm) {
    min-height: 400px;
  }
}

.estimate-item {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    padding-bottom: 15px;
  }
}

.estimate-item-button {
  @include media-breakpoint-down(sm) {
    button {
      flex-grow: 1;
    }
  }
}

.estimate-item-img {
  min-height: 190px;
  background-size: cover;
  background-repeat: no-repeat;
}

.estimate-item-info {
  display: flex;
  flex-direction: column;

  >p {
    color: $c-gray;
    margin-bottom: 0;
    font-size: 14px;
  }
}