@import '../../node_modules/slick-carousel/slick/slick.scss';

.combined-slick-dot .slick-list {
  max-height: 105px;
}
.combined-slick-main .slick-list {
  max-height: 410px;
}
section.team-slider {
  padding: 30px 0;

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }

  .slick-img-item {
    &:hover {
      .slick-img-team-info {
        transform: translateY(0);
      }
    }

    .slick-img-team-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      background-color: rgba($c-blue, 0.7);
      transform: translateY(100%);
      transition-duration: 0.3s;
      padding: 10px;
    }

    .slick-img-team-info-name {
      margin: auto;
    }

    .slick-img-team-info-position {
      margin: 0;
    }
  }
}

section.certificates {
  padding: 30px 0;

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }
}

.slick-with-img-wrapper {
  .slick-slider {
    padding: 0 60px;
  }
}

.slick-img-item-wrapper {
  padding: 0 10px;
}

.slick-img-item {
  display: flex;
  width: 100%;
  min-height: 230px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.prev-arrow-custom,
.next-arrow-custom {
  position: absolute;
  width: 40px;
  height: 60px;
  top: calc(50% - 30px);
  transition-duration: 0.3s;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &:hover {
    transform: scale(1.07);
  }

  &.next-arrow-custom-medium {
    width: 30px;
    height: 40px;
    top: calc(50% - 20px);
  }
}

.prev-arrow-custom {
  left: 0;
  background-image: url(../assets/images/left-arrow.svg);
}

.next-arrow-custom {
  right: 0;
  background-image: url(../assets/images/right-arrow.svg);
}

.next-arrow-custom-vertical {
  width: 16px;
  height: 40px;
  left: calc(50% - 8px);

  &.prev-arrow-custom {
    top: auto;
    bottom: -35px;
    transform: rotate(-90deg);
  }

  &.next-arrow-custom {
    top: -35px;
    transform: rotate(-90deg);
  }
}

.combined-slick-main-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;

  @include media-breakpoint-down(sm) {
    min-height: 200px;
  }

  @include media-breakpoint-up(md) {
    min-height: 360px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 420px;
  }
}

.combined-slick-dot-img {
  padding: 10px;
  min-height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.combined-slick-dot {
  margin-top: 10px;

  @include media-breakpoint-down(sm) {
    overflow: hidden;
    display: none;
  }

  /* the parent */
  .slick-list {
    margin: 0 -10px;
  }
}

.combined-slick-main {
  position: relative;

  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;

    .slick-list {
      max-height: 260px;
    }
  }

  .slick-slide > div {
    font-size: 0;
  }

  .slick-arrow {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 2;
    font-size: 0px;
    background-color: transparent;
    border: none;
    outline: none;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 1;
    transition-duration: 0.3s;

    @include media-breakpoint-down(sm) {
      width: 40px;
      background-size: 20px 20px;
    }

    // &:hover {
    //   background-color: rgba(black, 0.05);
    //   opacity: 1;
    // }
  }

  .slick-prev {
    left: 0;
    background-image: url(../assets/images/left-arrow-white.svg);
  }

  .slick-next {
    right: 0;
    background-image: url(../assets/images/rigth-arrow-white.svg);
  }
}
