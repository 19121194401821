section.ideas {
    padding: 30px 0;

    @include media-breakpoint-down(sm) {
        padding: 15px 0;

        .tabs-nav {
            display: none;
        }
    }
}

.tabs-wrapper {
    position: relative;
    display: flex;
    background-color: $c-blue;
    border-radius: 4px;

    &.tabs-wrapper-white {
        background-color: $c-white;
        border: 1px solid #dddddd;

        .nav-link {
            color: $c-gray;

            &.active {
                color: $c-blue;
            }
        }
    }
}

.ideas-with-line {
    position: relative;
    padding-left: 150px;
    margin-bottom: 40px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        display: block;
        width: 130px;
        height: 3px;
        background-color: $c-blue;

        @include media-breakpoint-down(sm) {
            width: 50px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-left: 70px;
        margin-bottom: 20px;
    }
}

.ideas-with-text {
    position: relative;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
        padding-left: 70px;
        margin-bottom: 20px;
    }
}


.ideas-item {
    margin: 0 auto;
}

.ideas-with-quote {
    position: relative;
    padding-left: 150px;
    margin-bottom: 40px;

    &:before {
        content: "";
        position: absolute;
        left: 100px;
        top: 10px;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../assets/images/left-double-arrow.svg);

        @include media-breakpoint-down(sm) {
            left: 20px;
        }

    }

    @include media-breakpoint-down(sm) {
        padding-left: 70px;
        margin-bottom: 20px;
    }
}

.ideas-with-image {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;

    img {
        max-width: 50%;

        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }
}

.ideas-with-title {
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }
}

.ideas-with-list {
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }

    ol {
        margin-bottom: 0;
        padding: 0;
        list-style: none;
        counter-reset: my-awesome-counter;

        li {
            padding-left: 50px;
            position: relative;
            counter-increment: my-awesome-counter;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &::before {
                content: counter(my-awesome-counter);
                position: absolute;
                left: 15px;
                top: -6px;
                color: $c-blue;
                font-weight: bold;
                font-size: 28px;
            }
        }
    }
}