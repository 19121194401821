section.projects {
    padding: 30px 0;

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }
}

.complete-project-modal {
    overflow: hidden !important;

    @include media-breakpoint-down(sm) {
        .modal-body {
            overflow-y: auto;
        }
    }

    .modal-dialog {
        width: 1000px;
        max-width: 95%;
        max-height: 95%;
        height: 95%;
    }

    .modal-content {
        max-height: 90%;
    }

    .modal-header {
        padding: 10px 1rem;
        border: none;
    }

    // .modal-body {
    //     overflow-y: auto;
    // }
}

.project-image-item {
    position: relative;
    overflow: hidden;
    min-height: 190px;
    margin-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
        .project-image-item-blue-bg {
            transform: translateY(0);
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
    }
}

.project-image-item-blue-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba($c-blue, 0.7);
    transform: translateY(100%);
    transition-duration: .3s;
    padding: 30% 10px 20px 10px;
    cursor: pointer;

    a,
    span {
        color: $c-white;
        text-align: center;
        margin-top: auto;
        font-size: 18px;
    }

    @include media-breakpoint-down(sm) {
        padding: 10px;
        background-color: transparent;
        transform: translateY(0);

        span {
            display: none;
        }

        h4 {
            text-align: left !important;
            margin-top: auto;
            margin-bottom: 0;
            font-size: 20px;
        }
    }
}

.complete-project-elem-text {
    position: absolute;
    height: 100%;

    @include media-breakpoint-down(sm) {
        position: relative;
        height: auto;
    }

    .complete-project-elem-text-button {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 100%;

        @include media-breakpoint-down(sm) {
            position: relative;
            bottom: 0;
            margin-top: 15px;
        }
    }

    .complete-project-elem-text-main {
        overflow-y: auto;
        height: calc(100% - 80px);

        @include media-breakpoint-down(sm) {
            height: auto;
            overflow: visible;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            font-size: 14px;

            &:before {
                content: "*";
                padding-right: 5px;
                line-height: 100%;
                vertical-align: text-bottom;
            }
        }
    }

    p {
        font-size: 14px;
    }
}