section.prices {
    padding: 30px 0;
    background-color: $c-lightgray;

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }
}

.prices-items>div:not(:first-child) .prices-item {
    &:before {
        content: '';
        position: absolute;
        left: 35px;
        bottom: calc(50% + 30px);
        display: block;
        width: 1px;
        height: calc(50% - 30px);
        background-color: $c-white;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.prices-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    &:after {
        content: '';
        position: absolute;
        left: 35px;
        top: calc(50% + 30px);
        display: block;
        width: 1px;
        height: calc(50% - 30px);
        background-color: $c-white;

        @include media-breakpoint-down(sm) {
            display: none;

        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        background-color: $c-white;
        box-shadow: 3px 5px 5px rgba($c-black, 0.5);
        margin-bottom: 15px;
        padding: 15px 20px;
    }
}

.prices-item-attr {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    background-color: $c-white;
    border-radius: 50%;
    background-position: 50% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
    box-shadow: -2px 4px 6px rgba($c-black, 0.3);

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.prices-item-main {
    position: relative;
    display: flex;
    flex-grow: 1;
    padding: 0 30px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        display: block;
        width: 30px;
        height: 1px;
        background-color: $c-white;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

.prices-item-main-img {
    flex-shrink: 0;
    min-width: 180px;
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.prices-item-main-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 20px 0;
    padding: 20px 30px;
    background-color: $c-white;
    box-shadow: 3px 5px 5px rgba($c-black, 0.5);

    @include media-breakpoint-down(sm) {
        background-color: transparent;
        box-shadow: none;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;

        h4 {
            text-align: center;
            font-size: 22px;
        }
    }

}

.prices-item-main-description-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;

    li {
        position: relative;
        display: inline-block;
        width: 50%;
        padding-right: 10px;

        @include media-breakpoint-down(sm) {
            width: 100%;
            padding-right: 0px;
        }

        &:before {
            content: "* "
        }
    }
}

.prices-item-button {
    position: relative;
    min-width: 150px;
    display: flex;

    &:before {
        content: '';
        position: absolute;
        right: 100%;
        top: 50%;
        display: block;
        width: 30px;
        height: 1px;
        background-color: $c-white;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    button {
        flex-grow: 1;
    }
}

.prices-button {
    position: relative;
    padding: 20px 0;

    &:before {
        content: '';
        position: absolute;
        left: 35px;
        top: 0;
        display: block;
        width: 1px;
        height: 20px;
        background-color: $c-white;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        display: flex;
        padding: 10px 0;

        button {
            flex-grow: 1;
        }
    }
}