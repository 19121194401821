.modal-stepper {

  .modal-content {
    min-height: 400px;

    @include media-breakpoint-down(sm) {
      min-height: auto;
    }
  }

  .modal-header {
    border: none;
    height: 0px;
    padding: 0;

    .close {
      position: absolute;
      z-index: 25;
      padding: 10px;
      right: 15px;
      top: 15px;
    }
  }

  .modal-lg {
    width: auto;
    max-width: 950px;
  }

  .modal-body {
    display: flex;
    padding: 0;
    background-image: linear-gradient(to right, $c-blue 28%, $c-white 28%, $c-white);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      background-image: none;
    }
  }

  .modal-body-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 28%;
    flex-shrink: 0;
    padding: 40px 30px;

    @include media-breakpoint-down(sm) {
      padding: 10px 15px;
      padding-top: 30px;
      width: 100%;
      background-color: $c-blue;
      text-align: center;
    }
  }

  .modal-body-right {
    display: flex;
    flex-direction: column;
    width: 70%;
    flex-shrink: 0;
    padding: 40px 30px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 10px 15px;
    }
  }

  .modal-body-right-header {
    display: flex;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .modal-body-right-header-text {
    margin-right: 30px;
  }

  .modal-body-right-header-logo {
    display: flex;
    align-items: flex-end;
    max-width: 60%;
    min-height: 66px;
    transition-duration: 0.3s;
    flex-shrink: 0;
    flex-grow: 1;
    padding-right: 15px;

    img {
      width: 100%
    }
  }

  .progress {
    background-color: #B2B3B3;
    height: 12px;
    margin-bottom: 25px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }

  .progress-bar {
    height: 12px;
    background-color: $c-blue;
  }

}

.finish-step {
  display: flex;
  margin: auto;

  .finish-step-boss {
    display: flex;
    max-width: 40%;
    margin-right: 5%;

    img {
      border-radius: 50%;
      overflow: hidden;
      max-width: 100%;
      align-self: center;
    }
  }
}

.finish-step-text-logo {


  @include media-breakpoint-down(sm) {
    display: none;
  }

  img {
    width: 100%;
  }
}

.finish-step-footer {
  padding-top: 10px;
  border-top: 1px solid black;

  p {
    margin: 0;
  }
}