.error-page-wrapper {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../assets/images/errorpage_bg.jpeg);

  .header {
    background-color: transparent;
    display: flex;
    flex-direction: column;
  }

  .header-top-wrapper-mobile-nav {
    img {
      filter: brightness(0) invert(1);
    }
  }

  .error-page-content {
    margin-top: 65px;
    padding: 30px 50px;
    align-self: flex-end;
    background-color: rgba(37, 189, 255, 0.7);

    @include media-breakpoint-down(sm) {
      margin-top: 0px;
    }
  }
}