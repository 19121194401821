section.dropdowns {
  padding: 30px 0;

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }

  .rc-collapse {
    display: flex;
    flex-wrap: wrap;
    border: none;
    border-radius: 0;
    background-color: inherit;

    ol,
    ul {
      padding-left: 15px;
    }
  }

  .rc-collapse-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-basis: 25%;
    min-width: 275px;
    flex-shrink: 0;

    transition: box-shadow .3s;
    border: none;

    &:after {
      z-index: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    }

    &.rc-collapse-item-active {
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      background-color: $c-white;
      z-index: 25;
      box-shadow: 0 0px 8px rgba($c-lightblack, 0.5);

      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 16px;
        display: block;
        z-index: 26;
        background-color: $c-white;
      }

      .rc-collapse-content {
        background-color: $c-white;
        box-shadow: 0 0px 8px rgba($c-lightblack, 0.5);
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;

        >.rc-collapse-content-box {
          margin-top: 6px;
        }
      }
    }
  }

  .rc-collapse-header {
    display: flex;
    align-items: center;
    padding: 10px 15px !important;
    color: $c-lightblack !important;

    &:focus {
      outline: none;
    }

    .svg-wrapper {
      flex-shrink: 0;
      width: 45px;
      height: 45px;
      margin-right: 15px;
      border-radius: 50%;
      border: 1px solid rgba($c-lightblack, 0.5);
      text-align: center;
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center center;

    }

    span {
      align-self: flex-start;
    }
  }

  .rc-collapse-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 25;
    color: $c-lightblack !important;

    >.rc-collapse-content-box {
      margin-top: 6px;
    }
  }
}

.dropdowns-svg-container {
  img {
    width: 50%;
    padding: 15px 0;

    @include media-breakpoint-down(sm) {
      width: 75%;
    }
  }
}

.dropdowns-add-img {
  img {
    max-width: 100%;
  }
}

.popup-styles-content {
  width: auto !important;
  width: calc(100% - 20px) !important;

  @include media-breakpoint-down(sm) {
    width: calc(100% - 20px) !important;
  }
}