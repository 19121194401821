section.video-block {
    padding: 30px 0 0;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.video-block-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url(../assets/images/mainbanner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.video-block-button-text {
    padding: 30px 40px;
    align-self: flex-end;
    background-color: rgba($c-blue, 0.7);
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.video-block-media-wrapper {
    display: flex;
    justify-content: flex-end;


}


.offer-mobile {
    display: none;
    justify-content: flex-end;
    padding: 25px 0;
    background-image: url(../assets/images/mainbanner.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
        display: flex;
    }

    .offer-mobile-blue-wrapper {
        padding: 25px;
        background-color: rgba($c-blue, 0.7);
    }
}