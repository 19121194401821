section.ceo {
    padding: 30px 0;

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }

    .mw-350 {
        @include media-breakpoint-down(sm) {
            max-width: 200px;
        }

    }

    &~.dropdowns {
        padding: 0;
    }
}

.ceo-footer {
    display: flex;
    margin-top: 40px;

    .ceo-footer-text {
        padding-bottom: 30px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 5px;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }
}

.ceo-main-text {
    font-size: 16px;
}