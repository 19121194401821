section.infoblock {
  padding: 30px 0;

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }

  [class^="col"]:last-of-type {
    .infoblock-item {
      @include media-breakpoint-down(sm) {
        padding-bottom: 0px;
      }
    }
  }
}

.infoblock-item {
  display: flex;
  flex-direction: column;
  height: 100%;

  td {
    font-size: 18px;
    color: $c-gray;
    padding: 5px 0;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 20px;
  }

  a {
    text-decoration: none;
  }
}

.infoblock-item-header {
  padding: 10px 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
}

.infoblock-item-about {
  display: flex;

  >div {
    width: 50%;
    flex-shrink: 0;
  }

  .infoblock-item-about-list {
    margin-bottom: auto;
    font-size: 18px;
    color: $c-gray;

    .infoblock-item-about-list-announce {
      font-size: 14px;
    }

    ul {
      list-style: none;
      padding: 0;

      @include media-breakpoint-down(sm) {
        margin: 0;
      }

      li {
        padding: 5px 0;

        a {
          color: $c-gray;
        }
      }
    }
  }
}
