section.reviews {
  padding: 30px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../assets/images/reviews-bg.jpg);

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }

  .review-form-wrapper {
    display: flex;
    height: 100%;
    padding-right: 20px;

    @include media-breakpoint-down(sm) {
      padding-right: 0px;
      margin-top: 25px;
    }
  }

  .review-form {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {

      input,
      textarea,
      .review-upload {
        margin-bottom: 10px;
      }
    }

  }

  .review-upload {
    position: relative;
    margin-bottom: 0;
    height: auto;

    label {
      display: flex;
      margin: 0;
      padding: 0.375rem 0.75rem;
      background-color: $c-white;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: $c-gray;

    }

    input[type="file"] {
      opacity: 0;
      position: absolute;
      top: 0;
    }
  }

  .review-button-wrapper {
    display: flex;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;

      button {
        flex-grow: 1;
      }
    }
  }
}