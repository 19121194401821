section.history-slider {
  padding: 30px 0;
  background-image: url(../assets/images/history-mainpage-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.history-slider-title {
  padding: 10px;
  background-color: rgba($c-blue, 0.5);
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
  font-size: 48px;
  color: $c-white;

  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }
}

.history-slick-slider-wrapper {



  .slick-list {
    background-color: $c-white;
    border-radius: 8px;
  }
}

.history-slick-slider {
  position: relative;
  border-radius: 10px;

  >.slick-slider {
    padding: 0 40px;
  }
}

.combined-slick-item-wrapper {
  position: relative;
}

.combined-slick-item-add-info {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -7px;
    right: 0;
    height: 1px;
    width: 80%;
    background-color: $c-lightgray;
  }
}

.combined-slick-item {
  padding: 35px 15px;

  .combined-slick-item-main-text {
    max-height: 120px;
    overflow-y: auto;
  }
  .combined-slick-item-main-text-no-title {
    max-height: 155px;
    overflow-y: auto;
  }
  .combined-slick-item-main-text-no-add-info {
    max-height: 164px;
    overflow-y: auto;
  }
}

.combined-slick-item-img-slider {
  @include media-breakpoint-down(sm) {
    padding-bottom: 30px;
  }
}

.combined-slick-item-img-slider-img-wrapper {
  display: flex;
  min-height: 100px;
  flex-direction: column;
}

.combined-slick-item-img-slider-img {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100px;
}
